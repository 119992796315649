exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-badania-kierowcow-js": () => import("./../../../src/pages/badania-kierowcow.js" /* webpackChunkName: "component---src-pages-badania-kierowcow-js" */),
  "component---src-pages-badania-usg-jader-js": () => import("./../../../src/pages/badania-usg/jader.js" /* webpackChunkName: "component---src-pages-badania-usg-jader-js" */),
  "component---src-pages-badania-usg-jamy-brzusznej-js": () => import("./../../../src/pages/badania-usg/jamy-brzusznej.js" /* webpackChunkName: "component---src-pages-badania-usg-jamy-brzusznej-js" */),
  "component---src-pages-badania-usg-klatki-piersiowej-js": () => import("./../../../src/pages/badania-usg/klatki-piersiowej.js" /* webpackChunkName: "component---src-pages-badania-usg-klatki-piersiowej-js" */),
  "component---src-pages-badania-usg-naczyn-color-doppler-js": () => import("./../../../src/pages/badania-usg/naczyn-color-doppler.js" /* webpackChunkName: "component---src-pages-badania-usg-naczyn-color-doppler-js" */),
  "component---src-pages-badania-usg-serca-js": () => import("./../../../src/pages/badania-usg/serca.js" /* webpackChunkName: "component---src-pages-badania-usg-serca-js" */),
  "component---src-pages-badania-usg-sutka-js": () => import("./../../../src/pages/badania-usg/sutka.js" /* webpackChunkName: "component---src-pages-badania-usg-sutka-js" */),
  "component---src-pages-badania-usg-tarczycy-js": () => import("./../../../src/pages/badania-usg/tarczycy.js" /* webpackChunkName: "component---src-pages-badania-usg-tarczycy-js" */),
  "component---src-pages-badania-usg-ukladu-moczowego-i-prostaty-js": () => import("./../../../src/pages/badania-usg/ukladu-moczowego-i-prostaty.js" /* webpackChunkName: "component---src-pages-badania-usg-ukladu-moczowego-i-prostaty-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-medycyna-pracy-js": () => import("./../../../src/pages/medycyna-pracy.js" /* webpackChunkName: "component---src-pages-medycyna-pracy-js" */),
  "component---src-pages-specjalisci-chirurg-js": () => import("./../../../src/pages/specjalisci/chirurg.js" /* webpackChunkName: "component---src-pages-specjalisci-chirurg-js" */),
  "component---src-pages-specjalisci-internista-js": () => import("./../../../src/pages/specjalisci/internista.js" /* webpackChunkName: "component---src-pages-specjalisci-internista-js" */),
  "component---src-pages-specjalisci-okulista-js": () => import("./../../../src/pages/specjalisci/okulista.js" /* webpackChunkName: "component---src-pages-specjalisci-okulista-js" */),
  "component---src-pages-specjalisci-radiolog-js": () => import("./../../../src/pages/specjalisci/radiolog.js" /* webpackChunkName: "component---src-pages-specjalisci-radiolog-js" */),
  "component---src-pages-specjalisci-urolog-js": () => import("./../../../src/pages/specjalisci/urolog.js" /* webpackChunkName: "component---src-pages-specjalisci-urolog-js" */),
  "component---src-pages-strefa-pacjenta-js": () => import("./../../../src/pages/strefa-pacjenta.js" /* webpackChunkName: "component---src-pages-strefa-pacjenta-js" */)
}

