/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

import "./src/styles/index.scss";
import React, { lazy, Suspense } from "react";
import { AnimatePresence } from "framer-motion";

// Importowanie podstron dynamicznie
export function wrapPageElement({ element, props }) {
  // Podstrony główne
  const LazyBadaniaKierowcow = lazy(() => import("./src/pages/badania-kierowcow"));
  const LazyMedycynaPracy = lazy(() => import("./src/pages/medycyna-pracy"));
  const LazyStrefaPacjenta = lazy(() => import("./src/pages/strefa-pacjenta"));

  // Podstrony specjaliści
  const LazyUrolog = lazy(() => import("./src/pages/specjalisci/urolog"));
  const LazyChirurg = lazy(() => import("./src/pages/specjalisci/chirurg"));
  const LazyRadiolog = lazy(() => import("./src/pages/specjalisci/radiolog"));
  const LazyInternista = lazy(() => import("./src/pages/specjalisci/internista"));
  const LazyOkulista = lazy(() => import("./src/pages/specjalisci/okulista"));

  // Podstrony badania USG
  const LazyJader = lazy(() => import("./src/pages/badania-usg/jader"));
  const LazyJamyBrzusznej = lazy(() => import("./src/pages/badania-usg/jamy-brzusznej"));
  const LazyKlatkiPiersiowej = lazy(() => import("./src/pages/badania-usg/klatki-piersiowej"));
  const LazyNaczynColorDoppler = lazy(() => import("./src/pages/badania-usg/naczyn-color-doppler"));
  const LazySerca = lazy(() => import("./src/pages/badania-usg/serca"));
  const LazySutka = lazy(() => import("./src/pages/badania-usg/sutka"));
  const LazyTarczycy = lazy(() => import("./src/pages/badania-usg/tarczycy"));
  const LazyUkladuMoczowego = lazy(() => import("./src/pages/badania-usg/ukladu-moczowego-i-prostaty"));

  // Dynamicznie renderuj podstrony tylko wtedy, gdy odpowiadają ścieżce
  let lazyPage;
  switch (props.path) {
    // Główne podstrony
    case "/badania-kierowcow":
      lazyPage = <LazyBadaniaKierowcow {...props} />;
      break;
    case "/medycyna-pracy":
      lazyPage = <LazyMedycynaPracy {...props} />;
      break;
    case "/strefa-pacjenta":
      lazyPage = <LazyStrefaPacjenta {...props} />;
      break;

    // Specjaliści
    case "/specjalisci/urolog":
      lazyPage = <LazyUrolog {...props} />;
      break;
    case "/specjalisci/chirurg":
      lazyPage = <LazyChirurg {...props} />;
      break;
    case "/specjalisci/radiolog":
      lazyPage = <LazyRadiolog {...props} />;
      break;
    case "/specjalisci/internista":
      lazyPage = <LazyInternista {...props} />;
      break;
    case "/specjalisci/okulista":
      lazyPage = <LazyOkulista {...props} />;
      break;

    // Badania USG
    case "/badania-usg/jader":
      lazyPage = <LazyJader {...props} />;
      break;
    case "/badania-usg/jamy-brzusznej":
      lazyPage = <LazyJamyBrzusznej {...props} />;
      break;
    case "/badania-usg/klatki-piersiowej":
      lazyPage = <LazyKlatkiPiersiowej {...props} />;
      break;
    case "/badania-usg/naczyn-color-doppler":
      lazyPage = <LazyNaczynColorDoppler {...props} />;
      break;
    case "/badania-usg/serca":
      lazyPage = <LazySerca {...props} />;
      break;
    case "/badania-usg/sutka":
      lazyPage = <LazySutka {...props} />;
      break;
    case "/badania-usg/tarczycy":
      lazyPage = <LazyTarczycy {...props} />;
      break;
    case "/badania-usg/ukladu-moczowego":
      lazyPage = <LazyUkladuMoczowego {...props} />;
      break;

    default:
      lazyPage = null;
  }

  return (
    <AnimatePresence mode="wait" initial={false}>
      {lazyPage ? (
        <Suspense fallback={<div>Ładowanie...</div>}>
          {lazyPage}
        </Suspense>
      ) : (
        element
      )}
    </AnimatePresence>
  );
}
